import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import { FaInstagram } from 'react-icons/fa'
import { GoMail } from 'react-icons/go'
import Header from '../components/header'
import Footer from '../components/footer'
import Layout from '../components/layout'
import Navigation from '../components/navigation'
import NewsSection from '../components/news-section'
import styles from '../components/connect.module.css'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import ConnectModal from '../components/connect-modal'
import Loadable from "@loadable/component"

const LoadableNewsSection = Loadable(() =>
  import('../components/news-section')
)

class RootIndex extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      showConnectModal: false
    }
  }

  handleConnectClicked() {
    this.setState({ showConnectModal: true })
  }

  handleCloseClicked() {
    this.setState({ showConnectModal: false })
  }


  render() {
    const siteMetadata = get(this, 'props.data.site.siteMetadata')
    const news = get(this, 'props.data.allContentfulNews.edges')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')
    const { showConnectModal } = this.state

    return (
      <div>
        <Helmet>
          <title>{`CONNECT | ${siteMetadata.title}`}</title>
          <meta name="description" content={siteMetadata.description} />
          <meta name="keywords" content={siteMetadata.keywords} />
          <meta name="author" content={siteMetadata.author} />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          {/*
          <meta property="og:url" content={ogUrl} />
          */}
          <meta property="og:type" content="website" />
          <meta property="og:title" content={siteMetadata.title} />
          <meta property="og:description" content={siteMetadata.description} />
          <meta property="og:image" content={siteMetadata.image} />
        </Helmet>

        <Header data={author.node} />
        <div className="wrapper" style={{ maxWidth: '760px' }}>
          <Img alt={siteMetadata.author} fluid={author.node.image.fluid} />
          <br />
          <div className={styles.connectCVContainer}>
            <div className={styles.connectButton}>
              <a href={author.node.cvFile.file.url} target="_blank">CV</a>
            </div>
            <div className={styles.connectButton} onClick={this.handleConnectClicked.bind(this)}>
              Let's Connect
            </div>
          </div>
          <div className={styles.connectBio}
            dangerouslySetInnerHTML={{
              __html: author.node.shortBio.childMarkdownRemark.html,
            }}
          />
          
        </div>

        <div className="wrapper">

          <LoadableNewsSection news={news} />
          <br /><br />
          <h2>Store</h2>
          Check out Ponnapa's store on <a href="https://www.artsy.net/artist/ponnapa-prakkamakul">Artsy</a>

        </div>
        <Footer data={author.node} />
        {showConnectModal &&
          <ConnectModal onClose={() => this.handleCloseClicked()} />
        }
      </div>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query ConnectQuery {
    site {
      siteMetadata {
        title
        siteUrl
        description
        image
        keywords
        author
      }
    }

    allContentfulNews(filter: { node_locale: { eq: "en-US" } }, sort: { fields: [startDate], order: ASC }) {
      edges {
        node { 
          title
          description {
            json
          }
          image {
            public_id
            secure_url
          }
          startDate
          endDate
          link
        }
      }
    }

    allContentfulPerson(filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }) {
      edges {
        node {
          name
          shortBio {
            childMarkdownRemark {
              html
            }
          }
          title
          cvFile {
            file {
              url
            }
          }
          image: image {
            fluid(
              maxWidth: 1180
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
